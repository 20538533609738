import React from 'react'

export default function Terms() {
	return (
		<>
			<h2><strong>Terms and Conditions</strong></h2>

			<p>Welcome to iFridge!</p>

			<p>These terms and conditions outline the rules and regulations for the use of The iFridge Project's Website, located at https://ifridge.tak-7.net.</p>

			<p>By accessing this website we assume you accept these terms and conditions. Do not continue to use iFridge if you do not agree to take all of the terms and conditions stated on this page.</p>

			<p>Don't copy our shit or we'll sue the hell outta ya</p>
		</>
	)
}